







































import Cookies from 'js-cookie';
import { Component, Vue, Watch } from 'vue-property-decorator';
import wordpressModule from '@/store/Wordpress';

@Component({
  components: {},
})
export default class WelcomeDialog extends Vue {
  welcomeDialog = false;

  setWelcomeCookie = false;

  get latestPost() {
    const [post] = wordpressModule.posts;
    return post || null;
  }

  get postsLoading() {
    return wordpressModule.loading;
  }

  dialogContent(post: WordpressPost) {
    if (!post) {
      return '';
    }

    let content: string | unknown = '';
    if (post.acf && post.acf.dialog_content) {
      content = post.acf.dialog_content;
    } else {
      content = post.excerpt.rendered;
    }

    return content;
  }

  dismissWelcome() {
    if (this.setWelcomeCookie) {
      Cookies.set('latest-post-date', this.latestPost.date, {
        expires: 400,
      });
    }
    this.welcomeDialog = false;
  }

  checkPosts() {
    const postDate = Cookies.get('latest-post-date');
    if (!postDate || (postDate && postDate !== this.latestPost.date)) {
      this.welcomeDialog = true;
    }
  }

  @Watch('postsLoading', { immediate: true })
  postsLoadingChanged(val: boolean) {
    if (val === false) {
      this.checkPosts();
    }
  }

  @Watch('welcomeDialog', { immediate: true })
  showWelcomeDialogChanged(val: boolean) {
    if (!val) {
      this.$emit('dismiss', 'welcome');
    }
  }
}
