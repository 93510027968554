





















import { Component, Vue } from 'vue-property-decorator';
import authModule from '@/store/Auth';
import wordpressModule from '@/store/Wordpress';
import dayjs from 'dayjs';

@Component({})
export default class TermsAndConditionsBanner extends Vue {
  get latestTermsDate() {
    return wordpressModule.latestTermsDate;
  }

  get gracePeriodEnd() {
    return wordpressModule.gracePeriodEnd;
  }

  get isGracePeriod() {
    return wordpressModule.isGracePeriod;
  }

  get ownerEmail() {
    return (
      authModule.activeOrganisation &&
      authModule.activeOrganisation.organisation.owner.email
    );
  }

  get isOwner() {
    return authModule.isOwner;
  }

  get ownerAgreeTcAt() {
    if (!authModule.user || !authModule.activeOrganisation) {
      return null;
    }
    let ownerAgreeTcAt = null;
    if (authModule.isOwner && authModule.user.agreeTcAt) {
      ownerAgreeTcAt = dayjs(authModule.user.agreeTcAt);
    } else if (authModule.activeOrganisation.organisation.owner.agreeTcAt) {
      ownerAgreeTcAt = dayjs(
        authModule.activeOrganisation.organisation.owner.agreeTcAt,
      );
    }
    return ownerAgreeTcAt;
  }

  get showBanner() {
    return (
      !authModule.isTrainingSession &&
      !!this.latestTermsDate &&
      !!this.gracePeriodEnd &&
      this.isGracePeriod &&
      !this.isOwner &&
      (!this.ownerAgreeTcAt ||
        this.ownerAgreeTcAt.isBefore(this.latestTermsDate))
    );
  }
}
