import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators';

import {
  User,
  Session,
  ApplicationRecord,
  Organisation,
  OrganisationUser,
  OrganisationType,
  OrgwideUpdate,
} from '@/api';

import store from '@/store';
import Cookies from 'js-cookie';
import i18n from '@/plugins/vue-i18n';
import { Route } from 'vue-router';
import { VueGtag } from 'vue-gtag';

import nextQuery from '@/nextQuery';

import legislationModule from '@/store/Legislation';
import wordpressModule from '@/store/Wordpress';

@Module({ dynamic: true, namespaced: true, name: 'auth', store })
class AuthModule extends VuexModule {
  ready = false;

  user: User | null = null;

  session: Session | null = null;

  organisationUsers: OrganisationUser[] = [];

  activeOrganisation: OrganisationUser | null = null;

  loginHooks: Array<
    (loginData: { user: User; session: Session }) => Promise<void>
  > = [];

  logoutHooks: Array<() => Promise<void>> = [];

  canAccessRoute = false;

  checkUpdating = true;

  updatingDialog = false;

  ownerUpdate = false;

  // Shows the subscription required dialog if set.
  // Will be set to the UUID of the button that triggered the notification in order to show specific content
  showSubscriptionRequiredDialog: string | null = null;

  updateItem: OrgwideUpdate | null = null;

  dashboardRouteKey = 0;

  get isLoggedIn() {
    return !!this.ready && !!this.user && !!this.session;
  }

  get defaultOrganisation() {
    const cookieId = Cookies.get('active-organisation-user');
    const org =
      this.organisationUsers.find(item => item.id === cookieId) ||
      this.organisationUsers.find(item => item.isDefault) ||
      this.organisationUsers[0] ||
      null;
    return org;
  }

  get activeOrganisationId() {
    return this.activeOrganisation
      ? this.activeOrganisation.organisation.id || ''
      : '';
  }

  get validSubscription() {
    return (
      this.isLoggedIn &&
      !!this.activeOrganisation &&
      (this.activeOrganisation.organisation.validSubscription ||
        this.activeOrganisation.organisation.manualVitalonlineAccess)
    );
  }

  get isActive() {
    return (
      this.isLoggedIn &&
      !!this.activeOrganisation &&
      this.activeOrganisation.organisation.isActive
    );
  }

  get canAccess() {
    return (roles: string[]): boolean => {
      if (roles.length === 0) {
        return true;
      }
      return roles.some(role => !!this.user && this.user.roles.includes(role));
    };
  }

  get isLegislationManager() {
    return this.user && this.user.roles.includes('legislation_managers');
  }

  get isOrganisationManager() {
    return this.user && this.user.roles.includes('organisation_managers');
  }

  get isOwnerOfAnything() {
    return !!this.organisationUsers.filter(orgUser => orgUser.isOwner).length;
  }

  get isOwner() {
    return !!this.activeOrganisation && this.activeOrganisation.isOwner;
  }

  get isManager() {
    return !!this.activeOrganisation && this.activeOrganisation.isManager;
  }

  get isProvider() {
    return (
      !!this.activeOrganisation &&
      this.activeOrganisation.organisation.type ===
        OrganisationType.TrainingProvider
    );
  }

  get isTrainingSession() {
    return (
      !!this.activeOrganisation &&
      this.activeOrganisation.organisation.type ===
        OrganisationType.TrainingSession
    );
  }

  get totpRequired() {
    return this.user && this.user.totpStatus !== 'verified';
  }

  // mutations

  @Mutation
  setActiveOrganisationCookies(organisationUser: OrganisationUser | null) {
    if (
      organisationUser &&
      organisationUser.id &&
      organisationUser.organisation &&
      organisationUser.organisation.id
    ) {
      Cookies.set('active-organisation-user', organisationUser.id);
      Cookies.set('active-organisation', organisationUser.organisation.id);
    } else {
      Cookies.remove('active-organisation-user');
      Cookies.remove('active-organisation');
    }
  }

  @Mutation
  setActiveOrganisation(organisationUser: OrganisationUser | null) {
    this.activeOrganisation = organisationUser;
  }

  @Mutation
  setReady() {
    this.ready = true;
  }

  @Mutation
  addLoginHook(
    hook: (loginData: { user: User; session: Session }) => Promise<void>,
  ) {
    this.loginHooks.push(hook);
  }

  @Mutation
  addLogoutHook(hook: () => Promise<void>) {
    this.logoutHooks.push(hook);
  }

  @Mutation
  setUser(user: User | null) {
    this.user = user;
  }

  @Mutation
  setSession(session: Session | null) {
    this.session = session;
  }

  @Mutation
  setOrganisationUsers(organisationUsers: OrganisationUser[]) {
    this.organisationUsers = organisationUsers;
  }

  @Mutation
  setCanAccessRoute(val: boolean) {
    this.canAccessRoute = val;
  }

  @Mutation
  setCheckUpdating(val: boolean) {
    this.checkUpdating = val;
  }

  @Mutation
  setUpdatingDialog(val: boolean) {
    this.updatingDialog = val;
  }

  @Mutation
  setOwnerUpdate(val: boolean) {
    this.ownerUpdate = val;
  }

  @Mutation
  setUpdateItem(val: OrgwideUpdate | null) {
    this.updateItem = val;
  }

  @Mutation
  bumpDashboardRouteKey() {
    this.dashboardRouteKey += 1;
  }

  @Mutation
  setShowSubscriptionRequiredDialog(val: string | null) {
    this.showSubscriptionRequiredDialog = val;
  }

  // actions

  @Action({ rawError: true })
  async setDefaultOrganisation(orgUser: OrganisationUser) {
    orgUser.isDefault = true;
    await orgUser.save();
    if (this.user) {
      await this.setUserOrganisations(this.user);
    }
  }

  @Action({ rawError: true })
  clearAuth() {
    this.setSession(null);
    this.setUser(null);
    this.setOrganisationUsers([]);
    this.setActiveOrganisationCookies(null);
    this.setActiveOrganisation(null);
    ApplicationRecord.jwt = undefined;
  }

  @Action({ rawError: true })
  async setUserInfo({ user, session }: { user: User; session: Session }) {
    // organisation users + organisations
    await this.setUserOrganisations(user);

    // user and session
    await this.setUserSession({ user, session });
  }

  @Action({ rawError: true })
  async setUserOrganisations(user: User) {
    // organisation users + organisations
    const organisationUsers = (
      await OrganisationUser.where({ user: user.id })
        .includes('organisation.userRelationships.user')
        .all()
    ).data;
    this.setOrganisationUsers(organisationUsers);
    if (!this.activeOrganisation) {
      this.setActiveOrganisationCookies(this.defaultOrganisation);
      this.setActiveOrganisation(this.defaultOrganisation);
    }

    // If the active organisation is updating show updating dialog
    if (
      this.activeOrganisation &&
      this.activeOrganisation.organisation.isUpdating
    ) {
      this.setUpdatingDialog(true);
    }
  }

  @Action({ rawError: true })
  async refreshUser() {
    if (this.user && this.user.id) {
      const user = (await User.find(this.user.id)).data;
      if (user) {
        this.setUser(user);
      }
    }
  }

  @Action({ rawError: true })
  async setUserSession({ user, session }: { user: User; session: Session }) {
    this.setSession(session);
    this.setUser(user);
  }

  @Action({ rawError: true })
  async login({
    email,
    password,
    otp,
    recoveryCode,
  }: {
    email: string;
    password: string;
    otp?: string;
    recoveryCode?: string;
  }) {
    try {
      const session = new Session({ email, password, otp, recoveryCode });
      await session.save();

      if (session && session.id && session.user.id) {
        ApplicationRecord.jwt = session.token;
        const user = (await User.find(session.user.id)).data;

        await this.setUserInfo({ user, session });
        await this.onLogin();
      }
    } catch (e) {
      this.clearAuth();
      throw e;
    }
  }

  @Action({ rawError: true })
  async registerUser({
    firstName,
    lastName,
    email,
    password,
  }: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
  }) {
    try {
      const user = new User({ firstName, lastName, email, password });
      await user.save();

      const session = new Session({ email, password });
      await session.save();

      if (session && session.id && session.user.id) {
        ApplicationRecord.jwt = session.token;

        await this.setUserSession({ user, session });
        await this.onLogin();
      }
    } catch (e) {
      this.clearAuth();
      throw e;
    }
  }

  @Action({ rawError: true })
  async registerOrganisation({
    organisationName,
    companyName,
    addressLine1,
    addressLine2,
    city,
    state,
    country,
    postcode,
  }: {
    organisationName: string;
    companyName: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    country: string;
    postcode: string;
  }) {
    if (!this.user) {
      throw new Error(i18n.t('users.alerts.missing') as string);
    }

    const organisation = new Organisation({
      name: organisationName,
      companyName,
      addressLine1,
      addressLine2,
      city,
      state,
      country,
      postcode,
      owner: this.user,
    });
    await organisation.save({ with: 'owner.id' });

    await this.setUserOrganisations(this.user);
    return organisation;
  }

  @Action({ rawError: true })
  async getCurrentUser() {
    try {
      const session = (await Session.first()).data;

      if (session && session.id && session.user.id) {
        const user = (await User.find(session.user.id)).data;

        await this.setUserInfo({ user, session });
        await this.onLogin();
      }
    } catch (e) {
      this.clearAuth();
      throw e;
    }
  }

  @Action({ rawError: true })
  async isOrganisationUpdating() {
    if (!this.activeOrganisationId) {
      return false;
    }
    try {
      this.setCheckUpdating(false);
      const org = (await Organisation.find(this.activeOrganisationId)).data;
      return org.isUpdating;
    } catch (e) {
      return false;
    } finally {
      this.setCheckUpdating(true);
    }
  }

  @Action({ rawError: true })
  async logout() {
    const session = new Session();
    try {
      await session.destroy();
    } finally {
      await this.onLogout();
      ApplicationRecord.jwt = undefined;
      window.location.href = process.env.BASE_URL;
    }
  }

  @Action({ rawError: true })
  async init() {
    this.addLoginHook(legislationModule.getActiveLegislations);
    this.addLoginHook(wordpressModule.getPosts);
    try {
      await this.getCurrentUser();
      // logged in
    } catch {
      // not logged in
    } finally {
      // add redirect on 401 middleware
      ApplicationRecord.middlewareStack.afterFilters.push(response => {
        if (response.status === 401) {
          ApplicationRecord.jwt = undefined;
          window.location.href = `${
            process.env.BASE_URL
          }login?next=${nextQuery()}`;
        }
      });
      // check for updating on 403 middleware
      ApplicationRecord.middlewareStack.afterFilters.push(async response => {
        if (response.status === 403) {
          if (this.checkUpdating && this.isLoggedIn) {
            // Check if the active organisation is updating
            const isUpdating = await this.isOrganisationUpdating();

            if (isUpdating) {
              this.setUpdatingDialog(true);
            } else {
              this.setUpdatingDialog(false);
              this.bumpDashboardRouteKey();
            }
          }
        }
      });

      // set ready
      this.setReady();
    }
  }

  // call all other modules that need login data
  @Action({ rawError: true })
  async onLogin() {
    const { user, session } = this;
    if (!user || !session) {
      throw new Error(i18n.t('authModule.alerts.missingUserSession') as string);
    }
    await Promise.all(this.loginHooks.map(hook => hook({ user, session })));
  }

  // call all other modules that need logout data
  @Action({ rawError: true })
  async onLogout() {
    await Promise.all(this.logoutHooks.map(hook => hook()));
  }

  @Action
  trackPageview({
    gtag,
    route,
    pageTitle,
  }: {
    gtag: VueGtag;
    route: Route;
    pageTitle?: string;
  }) {
    if (this.isOrganisationManager || this.isLegislationManager) {
      return;
    }

    const decodeEntities = (() => {
      // this prevents any overhead from creating the object each time
      const element = document.createElement('div');

      function decodeHTMLEntities(str?: string) {
        if (!str) {
          return '';
        }

        let newStr = str;

        if (newStr && typeof newStr === 'string') {
          // strip script/html tags
          newStr = newStr.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, '');
          newStr = newStr.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, '');
          element.innerHTML = newStr;
          newStr = element.textContent || '';
          element.textContent = '';
        }

        return newStr;
      }

      return decodeHTMLEntities;
    })();

    const decodedTitle = decodeEntities(pageTitle);

    gtag.pageview({
      page_path: route.path,
      page_title: decodedTitle || route.meta.title,
    });
  }

  @Action
  trackEvent({
    gtag,
    eventAction,
    eventCategory,
    eventLabel,
    eventValue,
  }: {
    gtag: VueGtag;
    eventAction: string;
    eventCategory?: string;
    eventLabel?: string;
    eventValue?: string;
  }) {
    if (this.isOrganisationManager || this.isLegislationManager) {
      return;
    }

    gtag.event(eventAction, {
      event_category: eventCategory,
      event_label: eventLabel,
      value: eventValue,
    });
  }
}

export default getModule(AuthModule, store);
