import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators';
import store from '@/store';

import { Wordpress } from '@/api';
import dayjs, { Dayjs } from 'dayjs';

@Module({ dynamic: true, namespaced: true, name: 'wordpress', store })
class WordpressModule extends VuexModule {
  loading = true;

  posts: WordpressPost[] = [];

  latestTerms: WordpressPost | null = null;

  latestTermsDate: Dayjs | null = null;

  gracePeriodEnd: Dayjs | null = null;

  isGracePeriod = false;

  @Mutation
  setPosts(posts: WordpressPost[]) {
    this.posts = posts;
  }

  @Mutation
  setLatestTerms(posts: WordpressPost[]) {
    if (posts.length) {
      const [terms] = posts;
      this.latestTerms = terms;
      if (this.latestTerms.date) {
        this.latestTermsDate = dayjs(this.latestTerms.date);
        this.gracePeriodEnd = this.latestTermsDate.add(14, 'day');
        const now = dayjs();
        this.isGracePeriod = now.isBefore(this.gracePeriodEnd);
      }
    }
  }

  @Mutation
  setLoading(val: boolean) {
    this.loading = val;
  }

  @Action({ rawError: true })
  async getPosts() {
    try {
      this.setLoading(true);
      const terms = await Wordpress.getTermsAndConditions();
      this.setLatestTerms(terms);
      const posts = await Wordpress.getLatestPosts();
      this.setPosts(posts);
    } catch (e) {
      // Error
    } finally {
      this.setLoading(false);
    }
  }
}

export default getModule(WordpressModule, store);
